import React from "react"

import Layout from "../../components/layoutGallery"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./exteriors.module.scss"
import LightBox from "../../components/lightbox"

export const query = graphql`
  query {
    placeholderImage: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "exterior" }
      }
    ) {
      edges {
        node {
          id
          absolutePath
          base
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

class ExteriorsPage extends React.Component {
  state = {
    imageId: "",
  }

  render() {
    const { data } = this.props
    const column1 = data.placeholderImage.edges.filter(
      (image, index) => index < 16
    )
    console.log(column1.length)
    const column2 = data.placeholderImage.edges.filter(
      (image, index) => index > 15 && index < 29
    )
    const column3 = data.placeholderImage.edges.filter(
      (image, index) => index > 28 && index < 42
    )
    const column4 = data.placeholderImage.edges.filter(
      (image, index) => index > 43 && index < 56
    )

    return (
      <Layout>
        <SEO
          title="Work-Exteriors"
          keywords={[`work`, `exterior`, `samples`, `services`, `architecture`]}
        />
        <h4 className={`${styles.heading} text-secondary`}>work / exteriors</h4>
        <div className={`row ${styles.gallery}`}>
          <div className={`col-lg-3 ${styles.column}`}>
            {column1.map((image, index) => {
              return (
                <div
                  key={image.node.id}
                  id={index}
                  className={styles.imageWrapper}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  onClick={e => {
                    this.setState({
                      imageId: e.target.parentNode.parentNode.parentNode.id,
                    })
                  }}
                >
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    alt={`exterior image ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
          <div id="column" className={`col-lg-3 ${styles.column}`}>
            {column2.map((image, index) => {
              return (
                <div
                  key={image.node.id}
                  id={column1.length + index}
                  className={styles.imageWrapper}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  onClick={e => {
                    this.setState({
                      imageId: e.target.parentNode.parentNode.parentNode.id,
                    })
                  }}
                >
                  <Img
                    className={styles.item}
                    fluid={image.node.childImageSharp.fluid}
                    alt={`exterior image ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
          <div className={`col-lg-3 ${styles.column}`}>
            {column3.map((image, index) => {
              return (
                <div
                  key={image.node.id}
                  id={column1.length + column2.length + index}
                  className={styles.imageWrapper}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  onClick={e => {
                    this.setState({
                      imageId: e.target.parentNode.parentNode.parentNode.id,
                    })
                  }}
                >
                  <Img
                    className={styles.item}
                    fluid={image.node.childImageSharp.fluid}
                    alt={`exterior image ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
          <div className={`col-lg-3 ${styles.column}`}>
            {column4.map((image, index) => {
              return (
                <div
                  key={image.node.id}
                  id={
                    column1.length + column2.length + column3.length + index + 2
                  }
                  className={styles.imageWrapper}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  onClick={e => {
                    this.setState({
                      imageId: e.target.parentNode.parentNode.parentNode.id,
                    })
                  }}
                >
                  <Img
                    className={styles.item}
                    fluid={image.node.childImageSharp.fluid}
                    alt={`exterior image ${index + 1}`}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <LightBox
          images={data.placeholderImage.edges}
          id={this.state.imageId}
        />
      </Layout>
    )
  }
}

export default ExteriorsPage
